import React, { Fragment, useRef, useState } from 'react';
import { Location } from '@reach/router';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { connect } from 'react-redux';
import styles from './styles.module.scss';
import Logo from '@images/logo.svg';
import LogoWhite from '@images/logo-white.svg';
import Button from '@components/Button';
import BookDialog from '@components/BookDialog';
import { Box } from '@mui/material';

const Navigation = ({ user, location }) => {
    const $wrapper = useRef(null);
    const [isNavigationMobileOpen, setNavigationMobileOpen] = useState(false);
    const [openBookDialog, setOpenBookDialog] = useState(false);
    const isTransparent =
        ['/'].indexOf(location.pathname) > -1 ||
        ['/weddings'].indexOf(location.pathname) > -1 ||
        ['/weddings/'].indexOf(location.pathname) > -1;

    const toggleMobileNavigation = () => {
        setNavigationMobileOpen(!isNavigationMobileOpen);
    };

    const handleMobileNavigationClose = e => {
        e.target === $wrapper.current && setNavigationMobileOpen(false);
    };
    const isWedding = location.pathname.includes('/weddings');
    const [navigationItemMenuOpen, setNavigationItemMenuOpen] = useState(null);

    const { ceremonies, foods } = useStaticQuery(graphql`
        query NavigationQuery {
            ceremonies: allMarkdownRemark(
                filter: { fileAbsolutePath: { regex: "/data/ceremonies/" } }
            ) {
                edges {
                    node {
                        frontmatter {
                            path
                            name: ceremonies_ceremony_title
                            image: ceremonies_ceremony_overview_image {
                                publicURL
                            }
                        }
                    }
                }
            }
            foods: allMarkdownRemark(
                filter: { fileAbsolutePath: { regex: "/data/food-and-drinks/" } }
                sort: { fields: [frontmatter___weddings_food_and_drink_priority], order: ASC }
            ) {
                edges {
                    node {
                        frontmatter {
                            path
                            name: weddings_food_and_drink_name
                            image: weddings_food_and_drink_overview_image {
                                publicURL
                            }
                        }
                    }
                }
            }
        }
    `);

    const navigation = [
        {
            title: 'Stay',
            link: '/stay',
            options: [
                {
                    title: 'Little barns & Shepherds Huts',
                    subtitle: 'Two Guests, one bedroom',
                    image: '/images/navigation/stay-small-bedroom.jpg',
                    link: '/stay?filter=little-bedroom',
                },
                {
                    title: 'One Bedroom Barns',
                    subtitle: 'Two Guests, one bedroom',
                    image: '/images/navigation/stay-one-bed-barns.jpg',
                    link: '/stay?filter=one-bedroom-barns',
                },
                {
                    title: 'Two Bedroom Barns',
                    subtitle: 'Four guests, two bedrooms',
                    image: '/images/navigation/stay-two-bed-barns.jpg',
                    link: '/stay?filter=two-bedroom-barns',
                },
                {
                    title: 'Four Bedroom Farmhouse',
                    subtitle: 'Eight guests, four bedrooms',
                    image: '/images/navigation/stay-four-bed-house.jpg',
                    link: '/stay?filter=four-bedroom-barns',
                },
                {
                    title: 'See all',
                    subtitle: 'View over 18 barns',
                    action: 'View all',
                    link: '/stay/barns-and-rooms',
                    collection: true,
                },
                {
                    title: 'Offers',
                    subtitle: 'Seasonal based events ready for booking now',
                    action: 'Special offers',
                    link: '/offers',
                    collection: true,
                },
            ],
        },
        {
            title: 'Dine',
            link: '/dine',
            options: [
                {
                    title: 'Breakfast',
                    subtitle: '',
                    image: '/images/navigation/dine-breakfast.png',
                    link: '/dine/menus/brunch',
                },
                {
                    title: 'All day dining',
                    subtitle: '',
                    image: '/images/navigation/all-day-dining.jpg',
                    link: '/dine/menus/lunch',
                },
                {
                    title: 'Dinner',
                    subtitle: '',
                    image: '/images/navigation/dinner-menu.png',
                    link: '/dine/menus/dinner',
                },
                {
                    title: 'Afternoon Tea',
                    subtitle: '',
                    image: '/images/navigation/afternoon-tea.png',
                    link: '/dine/menus/afternoon-tea',
                },
                {
                    title: 'See more',
                    subtitle: 'View over 8 options',
                    action: 'View all',
                    link: '/dine',
                    collection: true,
                },
            ],
        },
        {
            title: 'Spa',
            link: '/spa',
            options: [
                {
                    title: 'Spa Days',
                    subtitle: 'Relax and unwind with a luxury spa day at Retreat East',
                    image: '/images/navigation/spa-days-image.png',
                    link: '/spa/treatments/spa-days',
                },
                {
                    title: 'Body Treatments',
                    subtitle: 'Unwind and epxerience a tranquil moment of pure relaxation',
                    image: '/images/navigation/spa-body.png',
                    link: '/spa/treatments/body',
                },
                {
                    title: 'Face',
                    subtitle:
                        'Our facial treatments combine ancient botanicals with modern science',
                    image: '/images/navigation/spa-face.png',
                    link: '/spa/treatments/face',
                },
                {
                    title: 'Packages',
                    subtitle:
                        'View our recommended packages per person for a extra special experience',
                    image: '/images/navigation/spa-packages.png',
                    link: '/spa/treatments/packages',
                },
                {
                    title: 'View all',
                    subtitle:
                        'We have over 20 treatments available in a range of categories ready to book',
                    action: 'View all',
                    link: '/spa',
                    collection: true,
                },
            ],
        },
        {
            title: 'Explore',
            options: [
                {
                    title: "What's on",
                    subtitle: '',
                    image: '/images/navigation/whats-on-new.png',
                    link: '/whats-on',
                },
                {
                    title: 'Events',
                    subtitle: '',
                    image: '/images/navigation/events.png',
                    link: '/events',
                },
                {
                    title: 'Explore',
                    subtitle: '',
                    image: '/images/navigation/explore-new.png',
                    link: '/explore',
                },
                {
                    title: 'Meet the team',
                    image: '/images/navigation/team-photo.jpg',
                    subtitle: '',
                    link: '/meet-the-team',
                },
                {
                    title: 'Sustainability',
                    subtitle: '',
                    image: '/images/navigation/sustainability-turnip.jpeg',
                    link: '/sustainability',
                },
            ],
        },
        {
            title: 'Gifts',
            link: '/shop',
            options: [
                // {
                //     title: 'Afternoon Tea for Two',
                //     subtitle: 'Indulge in a selection of delicious sweet & savoury delights',
                //     image: '/images/navigation/gifts-afternoon-tea-for-two.jpeg',
                //     link: '/shop/products/afternoonteafortwo',
                // },
                // {
                //     title: 'Spa, Stay & Supper',
                //     subtitle:
                //         'Escape to the tranquillity of Retreat East with our Stay, Spa & Supper package.',
                //     image: '/images/navigation/gifts-stay-spa-and-supper.jpeg',
                //     link: '/shop/products/spastaysupper',
                // },
                // {
                //     title: 'Tasting Menu for Two',
                //     subtitle: 'The ultimate food experience for any gourmet food lover!',
                //     image: '/images/navigation/gifts-tasting-menu-for-two.jpeg',
                //     link: '/shop/products/tasting-menu',
                // },
                {
                    title: 'Our Gin',
                    subtitle: 'Meet our limited-edition Retreat East Suffolk gin',
                    action: 'View',
                    image: '/images/navigation/our-gin.jpg',
                    link: '/explore/our-gin',
                },
                {
                    title: 'Buy Vouchers',
                    subtitle: 'View our selection of vouchers to gift to others',
                    action: 'View vouchers',
                    link: 'https://retreat-east.vouchercart.com/app',
                    collection: true,
                },
                {
                    title: 'View all',
                    subtitle: 'View our selection purchasable gifts, experiences and vouchers',
                    action: 'View all',
                    link: '/shop',
                    collection: true,
                },
            ],
        },
        {
            title: 'Weddings',
            link: '/weddings',
            options: [
                {
                    title: 'Ceremonies',
                    subtitle: '',
                    image: '/images/navigation/weddings-ceremonies.png',
                    link: '/weddings/ceremonies',
                },
                {
                    title: 'Accommodation',
                    subtitle: '',
                    image: '/images/navigation/weddings-accommodation.png',
                    link: '/weddings/accommodation',
                },
                {
                    title: 'Food & Drink',
                    subtitle: '',
                    image: '/images/navigation/weddings-food-and-drink.png',
                    link: '/weddings/food-and-drinks',
                },
                {
                    title: 'Activities',
                    subtitle: '',
                    image: '/images/navigation/weddings-activities.png',
                    link: '/weddings/activities',
                },
                {
                    title: 'Read More',
                    subtitle: '',
                    action: 'Visit Weddings',
                    link: '/weddings',
                    collection: true,
                },
            ],
        },
        {
            title: 'Book',
            onClick: () => setOpenBookDialog(true),
            // link: '?book=true',
            button: true,
        },
    ];

    // End of new mega menu data

    const weddingNavigation = [
        {
            title: 'Ceremonies',
            link: '/weddings/ceremonies',
            options: ceremonies?.edges
                ?.slice(0, 4)
                ?.map(({ node: { frontmatter: { path, name, image } } }) => ({
                    title: name,
                    link: path,
                    image: image?.publicURL,
                })),
        },
        {
            title: 'Accommodation',
            link: '/weddings/accommodation',
        },
        {
            title: 'Food & Drinks',
            link: '/weddings/food-and-drinks',
            options: foods?.edges
                ?.slice(0, 4)
                ?.map(({ node: { frontmatter: { path, name, image } } }) => ({
                    title: name,
                    link: path,
                    image: image?.publicURL,
                })),
        },
        {
            title: 'Activities',
            link: '/weddings/activities',
        },
        {
            title: 'Gallery',
            link: '/weddings/gallery',
        },
        {
            title: 'Packages & Pricing',
            link: '/weddings/special-packages',
        },
        {
            title: 'FAQ',
            link: '/weddings/faqs',
        },
        {
            title: 'Contact',
            link: '/weddings/contact-us',
            button: true,
        },
    ];

    return (
        <Fragment>
            <BookDialog open={openBookDialog} onClose={() => setOpenBookDialog(false)} />
            <div
                ref={$wrapper}
                className={`${styles.navigationWrapper} ${
                    isNavigationMobileOpen ? styles.navigationWrapperMobileOpen : ''
                }`}
                onClick={isNavigationMobileOpen ? handleMobileNavigationClose : undefined}
            >
                <nav
                    className={`${styles.navigation} ${
                        isTransparent ? styles.navigationTransparent : ''
                    } ${isWedding ? styles.navigationIsWedding : ''} ${
                        navigationItemMenuOpen !== null ? styles.navigationOptionsOpen : ''
                    }`}
                >
                    <ul
                        className={`${styles.navigationContent} ${styles.navigationContentCentered}`}
                    >
                        {!isWedding && <li style={{ width: '100%' }} />}

                        {(isWedding ? weddingNavigation : navigation).map(
                            (
                                {
                                    title,
                                    link,
                                    logo,
                                    options,
                                    button,
                                    buttonVariant,
                                    hidden,
                                    onClick,
                                },
                                index
                            ) => (
                                <Fragment key={`${link}_${title}`}>
                                    {(isWedding ? index === 0 : index === 3) && (
                                        <li className={styles.navigationItemLogo}>
                                            <Link
                                                to={
                                                    location.pathname === '/weddings'
                                                        ? '/'
                                                        : isWedding
                                                        ? '/weddings'
                                                        : '/'
                                                }
                                                className={`${styles.navigationLogo}`}
                                                aria-label="Logo"
                                                onClick={toggleMobileNavigation}
                                            >
                                                <Logo />
                                            </Link>
                                        </li>
                                    )}

                                    <li
                                        className={styles.navigationItem}
                                        onMouseEnter={
                                            !!options?.length
                                                ? () => setNavigationItemMenuOpen(index)
                                                : undefined
                                        }
                                        onMouseLeave={
                                            !!options?.length
                                                ? () => setNavigationItemMenuOpen(null)
                                                : undefined
                                        }
                                    >
                                        {/* Hidden item is used for alignment purposes */}
                                        {/* Logo is part of nav items in order to force it into the center */}
                                        {button ? (
                                            <Button
                                                className={
                                                    buttonVariant
                                                        ? styles.navigationInverseButton
                                                        : styles.navigationButton
                                                }
                                                link={link}
                                                onClick={() => {
                                                    onClick?.();
                                                    toggleMobileNavigation();
                                                }}
                                                inverse={buttonVariant === 'inverse'}
                                            >
                                                {title}
                                            </Button>
                                        ) : (
                                            <Fragment>
                                                <Link
                                                    className={styles.navigationItemLink}
                                                    activeClassName={
                                                        styles.navigationItemLinkActive
                                                    }
                                                    to={link}
                                                >
                                                    <span
                                                        className={styles.navigationItemLinkTitle}
                                                    >
                                                        {title}
                                                    </span>

                                                    {/* render caret icon if options declared */}
                                                    {!!options?.length && (
                                                        <span
                                                            className={
                                                                !isTransparent
                                                                    ? `${styles.navigationItemLinkIconTransparent} ${styles.navigationItemLinkIcon}`
                                                                    : `${styles.navigationItemLinkIcon}`
                                                            }
                                                        >
                                                            <FontAwesomeIcon
                                                                className={
                                                                    styles.navigationItemLinkIconCaretDown
                                                                }
                                                                icon={['fas', 'chevron-down']}
                                                            />
                                                            <FontAwesomeIcon
                                                                className={
                                                                    styles.navigationItemLinkIconCaretUp
                                                                }
                                                                icon={['fas', 'chevron-up']}
                                                            />
                                                        </span>
                                                    )}
                                                </Link>

                                                {!!options?.length && (
                                                    <ul className={styles.navigationItemOptions}>
                                                        <li
                                                            className={
                                                                styles.navigationItemOptionsBorder
                                                            }
                                                        />
                                                        {options?.map(
                                                            ({
                                                                image,
                                                                title,
                                                                subtitle,
                                                                link,
                                                                collection,
                                                                action,
                                                            }) => (
                                                                <li
                                                                    className={
                                                                        styles.navigationItemOptionsItem
                                                                    }
                                                                >
                                                                    <Box
                                                                        component={
                                                                            /^((http|https|tel|mailto):)/.test(
                                                                                link
                                                                            )
                                                                                ? 'a'
                                                                                : Link
                                                                        }
                                                                        to={
                                                                            /^((http|https|tel|mailto):)/.test(
                                                                                link
                                                                            )
                                                                                ? undefined
                                                                                : link
                                                                        }
                                                                        href={
                                                                            /^((http|https|tel|mailto):)/.test(
                                                                                link
                                                                            )
                                                                                ? link
                                                                                : undefined
                                                                        }
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        className={
                                                                            styles.navigationItemOptionsItemLink
                                                                        }
                                                                    >
                                                                        {collection ? (
                                                                            <div
                                                                                className={
                                                                                    styles.navigationItemOptionsItemButton
                                                                                }
                                                                            >
                                                                                <p>{action}</p>
                                                                            </div>
                                                                        ) : (
                                                                            <div
                                                                                className={
                                                                                    styles.navigationItemOptionsItemImageWrapper
                                                                                }
                                                                            >
                                                                                <img
                                                                                    className={
                                                                                        styles.navigationItemOptionsItemImage
                                                                                    }
                                                                                    src={image}
                                                                                    width="100%"
                                                                                    alt={`${title} image`}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                        <p>
                                                                            <strong>{title}</strong>
                                                                        </p>
                                                                        <p
                                                                            className={
                                                                                styles.navigationItemOptionsItemDescription
                                                                            }
                                                                        >
                                                                            {subtitle}
                                                                        </p>
                                                                    </Box>
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                )}
                                            </Fragment>
                                        )}
                                    </li>
                                </Fragment>
                            )
                        )}
                    </ul>
                </nav>
            </div>
            <div
                className={`${styles.navigationMobileToggle} ${
                    isTransparent ? styles.navigationMobileToggleTransparent : ''
                }`}
                aria-label="Mobile navigation"
            >
                <Link
                    to={isWedding ? '/weddings' : '/'}
                    className={styles.navigationLogoMobile}
                    aria-label="Logo"
                    onClick={toggleMobileNavigation}
                >
                    {isTransparent ? <LogoWhite /> : <Logo />}
                </Link>

                <button onClick={toggleMobileNavigation}>
                    <FontAwesomeIcon icon={faBars} />
                </button>
            </div>
        </Fragment>
    );
};

const NavigationWithLocation = props => (
    <Location>{locationContext => <Navigation {...locationContext} {...props} />}</Location>
);

const mapStateToProps = ({ user }) => ({ user });
export default connect(mapStateToProps)(NavigationWithLocation);
